.RegisterWrap {
    flex-direction: column;
    .registerLHS {
        width: auto;
        height: auto;
        padding-bottom: 10px;
        .logo {
            height: 43px;
            margin-top: 5%; }
        .loginFooter {
            display: none; } }
    .registerRHS {
        height: 100vh;
        width: auto;
        align-items: flex-start;
        .loginForm {
            padding: 1rem 1.5rem; } } }

.registrationForm {
    &.rgsWrapper {}
    margin: 0px;
    .loginTitle {
        margin: 28px 28px 16px 28px; }
    form {
        padding: 1rem 1.5rem; }
    .registerFormWrap {
        .firstLastField {
            flex-direction: column; }
        .tickAndBtn {
            flex-direction: column;
            .inputWrap:first-child {
                flex-direction: row; }
            .oneThirdContainer {
                width: auto; } } } }

.mobileView {
    flex-direction: column;
    .logoImg {
        display: block;
        height: 40px;
        margin: 10px auto; }
    .leftSideBar {
        align-self: end;
        z-index: 10;
        position: absolute;
        display: none;
        background: #5592df;
        .closeMenu {
            font-size: 2rem; }
        img {
            display: none; }
        .navItem {
            .navLabel {
                color: #ffffff; } }
        .logoutLink {
            width: auto;
            color: #ffffff;
            margin: 0;
            svg {
                fill: #ffffff; } } }
    .pageRight {
        margin-left: 0px;
        min-height: auto;
        .userSection {
            .userInfoWrap {
                display: none; }
            .toggleMenu {
                font-size: 2rem;
                color: #22A4EE; } }
        .imgGenerateWrap {
            padding: 0px;
            display: flex;
            flex-direction: column;
            img {
                height: auto; }
            #stopwatch {
                font-size: 2rem;
                padding: 1rem;
                margin: 0.5rem 0rem;
                position: static; }
            .blackBtn {
                right: 10px;
                bottom: 70px; } }
        .recordingBtns {
            grid-gap: 0rem;
            gap: 0rem;
            .btn {
                text-align: left;
                height: auto;
                line-height: 1.25rem;
                padding: 6px 13px; } }
        .recordingListWrap {
            .recordList {
                width: 100%;
                overflow: auto;
                .recentAudio {
                    flex-direction: row !important;
                    align-items: center !important;
                    .audioFile {
                        width: 80%  !important; } }
                .recordItem {
                    flex-direction: column;
                    align-items: flex-start;
                    height: auto;
                    padding: 0px;
                    margin: 12px auto;
                    .audioFile {
                        width: 100%;
                        margin: 2px 0px;
                        audio {
                            width: 100%;
                            padding: 0 10px; } }
                    .audioName {
                        width: auto;
                        margin: 2px 0px; }
                    .audioTime {
                        width: auto;
                        margin: 2px 0px; }
                    .fileStatus {
                        width: auto;
                        margin: 2px 0px; } } }
            .listFooter {
                margin-top: 10px;
                button {
                    border: 1px solid #22A4EE; } } }
        .totalBox {
            flex-direction: column;
            .adminDetails {
                justify-content: center;
                > div {
                    align-items: center;
                    margin-right: 0px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    img {
                        height: 70px;
                        width: 70px; }
                    > div {
                        margin-right: 0px;
                        align-items: center;
                        label {
                            font-size: 18px;
                            text-align: center;
                            margin: 1rem auto 0.65rem auto; }
                        span {
                            font-size: 1.1rem; } } } }
            .btnExcelGreen {
                margin: 1rem auto 0rem auto; } }
        .projectCoordinatorContent {
            .projectContent {
                .projectTableWrap {
                    header {
                        flex-direction: column;
                        margin-bottom: 15px;
                        .search {
                            width: 100%;
                            input {
                                height: auto;
                                width: 100%; } } }
                    .projectTable {
                        table {
                            thead {
                                display: none; }
                            tbody {
                                tr {
                                    display: flex;
                                    flex-wrap: wrap;
                                    padding-bottom: 0.75rem;
                                    border-bottom: 3px solid #dddddd;
                                    audio {
                                        width: revert; }
                                    .darkGreenBtn {
                                            height: 40px;
                                            line-height: initial; }
                                    td {
                                        width: 100%; }
                                    td:nth-child(4) {
                                        width: 50%; }
                                    td:nth-child(5) {
                                        width: 50%;
                                        border-left: 1px solid gray; }
                                    td:nth-child(6) {
                                        width: 50%;
                                        margin-top: 10px; }
                                    td:nth-child(7) {
                                        width: 50%;
                                        margin-top: 10px;
                                        border-left: 1px solid gray; }
                                    td:last-child button {
                                        width: 100%; } } } } }
                    .paginationWrap {
                        flex-direction: column; } } }
            .mobileDashboard {
                .projectTableWrap {
                    .projectTable {
                        table {
                            tbody {
                                tr {
                                    margin-top: 1rem;
                                    td {
                                        width: 50%; } }
                                td:nth-child(2) {
                                    border-left: 1px solid gray; }
                                td:nth-child(3) {
                                    margin-top: 10px;
                                    padding-left: 20px; }
                                td:nth-child(4) {
                                    border-left: 1px solid gray;
                                    margin-top: 10px; }
                                td:nth-child(5) {
                                    border: none !important;
                                    width: 100% !important; } } } } } } }
        select {
            margin-right: 0px;
            width: 100%;
            font-size: 10px; } } }
#stfDialogPlace {
    .stf__modalDialogsPlace {
        .Modal-module_overlay__21YAk {
            .Modal-module_dialog__2UBIn {
                .Modal-module_modalBody__3_RoW {
                    .addNewUserPop {
                        .projectTableWrap {
                            header {
                                flex-direction: column;
                                margin-bottom: 15px;
                                .search {
                                    width: 100%;
                                    input {
                                        height: auto;
                                        width: 100%; } } }
                            .projectTable {
                                table {
                                    thead {
                                        display: none; }
                                    tbody {
                                        tr {
                                            display: flex;
                                            flex-wrap: wrap;
                                            padding-bottom: 0.75rem;
                                            border-bottom: 3px solid #dddddd;
                                            margin-top: 1rem;
                                            max-width: 18rem;
                                            td {
                                                width: 100%;
                                                overflow: auto; }
                                            audio {
                                                width: 100%; }
                                            td:nth-child(3) {
                                                width: 50%; }
                                            td:nth-child(4) {
                                                width: 50%; } } } } }
                            .paginationWrap {
                                flex-direction: column; } } } } } } } }



@media (min-width:480px) {
    .RegisterWrap {
        flex-direction: row;
        .registerLHS {
            width: 450px;
            height: 100vh;
            padding-bottom: 38px;
            .logo {
                height: 55px;
                margin-top: 55%; }
            .loginFooter {
                display: block; } }
        .registerRHS {
            width: calc(100% - 450px);
            height: auto;
            align-items: center;
            .loginForm {
                padding: auto; } } }
    .registrationForm {
        &.rgsWrapper {}
        margin: 0 109px 0 107px;
        .loginTitle {
            margin-bottom: 38px; }
        form {
            padding: 0rem; }
        .registerFormWrap {
            .firstLastField {
                flex-direction: row; }
            .tickAndBtn {
                align-items: baseline;
                flex-direction: row;
                .inputWrap:first-child {
                    flex-direction: column; }
                .oneThirdContainer {
                    width: 30%; } } } }
    .leftSideBar {
        .closeMenu {
            display: none; } }
    .mobileView {
        flex-direction: row;
        .logoImg {
            display: none; }
        .leftSideBar {
            align-self: flex-start;
            z-index: 0;
            position: fixed;
            display: flex;
            background: #ffffff;
            .closeMenu {
                display: none; }
            img {
                display: block; }
            .navItem {
                .navLabel {
                    color: #3E3E4F; } }
            .logoutLink {
                width: 280px;
                color: #3E3E4F;
                margin: 0 auto;
                svg {
                    fill: #717394; } } }
        .pageRight {
            margin-left: 300px;
            min-height: 100vh;
            .userSection {
                .userInfoWrap {
                    display: flex; }
                .toggleMenu {
                    display: none; } }
            .imgGenerateWrap {
                padding: 10px;
                display: block;
                flex-direction: row;
                img {
                    height: 410px; }
                #stopwatch {
                    font-size: 2rem;
                    padding: 1rem 1.5rem;
                    margin: 0rem;
                    position: absolute; }
                .blackBtn {
                    right: 25px;
                    bottom: 23px; } }
            .recordingBtns {
                grid-gap: 1rem;
                gap: 1rem;
                .btn {
                    text-align: center;
                    height: 40px;
                    line-height: 40px;
                    padding: 0px 13px; } }
            .recordingListWrap {
                .recordList {
                    .recentAudio {
                        flex-direction: column;
                        align-items: flex-start;
                        .audioFile {
                            width: 30% !important; } }
                    .recordItem {
                        flex-direction: row;
                        align-items: center;
                        height: 40px;
                        padding: 0px 15px;
                        margin: 0 auto;
                        .audioFile {
                            width: 28%;
                            audio {
                                width: 100%;
                                padding: 0; } }
                        .audioName {
                            width: 40%;
                            margin-left: 10px; }
                        .audioTime {
                            width: auto;
                            margin: 0 1rem; }
                        .fileStatus {
                            width: auto; } } }
                .listFooter {
                    margin-top: 23px;
                    button {
                        border: none; } } }
            .totalBox {
                flex-direction: row;
                .adminDetails {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    > div {
                        margin-right: 76px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        img {
                            height: auto;
                            width: auto; }
                        > div {
                            margin-right: 76px;
                            align-items: flex-start;
                            padding-left: 8px;
                            label {
                                font-size: 18px;
                                text-align: left;
                                margin: 0rem 1rem; }
                            span {
                                font-size: 1.1rem;
                                margin: 0rem 1rem; } } } }
                .btnExcelGreen {
                    margin: 0rem; } }
            .projectCoordinatorContent {
                .projectContent {
                    .projectTableWrap {
                        header {
                            flex-direction: row;
                            margin-bottom: 0px;
                            align-items: baseline;
                            .search {
                                width: auto;
                                input {
                                    height: 40px;
                                    width: 335px; } } }
                        .projectTable {
                            table {
                                thead {
                                    display: block; }
                                tbody {
                                    margin-top: 0rem;
                                    tr {
                                        display: inherit;
                                        flex-direction: row;
                                        padding-bottom: 0rem;
                                        border-bottom: none;
                                        .darkGreenBtn {
                                            height: 40px;
                                            line-height: initial; }
                                        audio {
                                            width: 210px; }
                                        td {
                                            width: 1%; }
                                        td:nth-child(4) {
                                            width: 1%; }
                                        td:nth-child(5) {
                                            border: none;
                                            width: 1%; }
                                        td:nth-child(6) {
                                            margin-top: 0px;
                                            width: 1%; }
                                        td:nth-child(7) {
                                            width: 1%;
                                            border: none;
                                            margin-top: 0px; }
                                        td:last-child {
                                            width: 1%; } } } } }
                        .paginationWrap {
                            flex-direction: row; } } }
                .mobileDashboard {
                    .projectTableWrap {
                        .projectTable {
                            table {

                                tbody {
                                    tr {
                                        td {
                                            width: 1%; }
                                        td:nth-child(2) {
                                            border: none; }
                                        td:nth-child(3) {
                                            margin-top: 0px; }
                                        td:nth-child(4) {
                                            border: none;
                                            width: 1%;
                                            margin-top: 0px; }
                                        td:nth-child(5) {
                                            width: 1% !important; } } } } } } } }
            select {
                margin-right: 14px;
                width: 190px;
                font-size: 14px; } } }

    #stfDialogPlace {
        .stf__modalDialogsPlace {
            .Modal-module_overlay__21YAk {
                .Modal-module_dialog__2UBIn {
                    max-width: max-content;
                    .Modal-module_modalBody__3_RoW {
                        .addNewUserPop {
                            padding: 0rem 2rem;
                            .projectTableWrap {
                                header {
                                    flex-direction: column;
                                    margin-bottom: 15px;
                                    .search {
                                        width: 100%;
                                        input {
                                            height: auto;
                                            width: 100%; } } }
                                .projectTable {
                                    table {
                                        thead {
                                            display: block; }
                                        tbody {
                                            tr {
                                                display: inherit;
                                                flex-direction: row;
                                                padding-bottom: 0rem;
                                                border-bottom: none;
                                                margin-top: 0rem;
                                                max-width: 18rem;
                                                td {
                                                    width: 1%; }
                                                audio {
                                                    width: 210px; }
                                                td:nth-child(3) {
                                                    width: 1%; }
                                                td:nth-child(4) {
                                                    width: 1%; } } } } }
                                .paginationWrap {
                                    flex-direction: row; } } } } } } } } }


