.generateImagePage {}
.imgGenerateWrap {
    background: #FFFFFF;
    box-shadow: 4px 4px 10px #E8EFFC;
    padding: 10px;
    position: relative;
    img {
        width: 100%;
        height: 410px; }
    span {
        position: absolute;
        right: 42%;
        bottom: 23px;
        font-size: 2rem;
        padding: 1rem 1.5rem;
        background: black;
        opacity: 0.9;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        svg {
            margin-right: 8px; }
        &:first-child {
            background: #78CF4D;
            color: #fff; }
        &:last-child {
            color: $basePink; } }
    .blackBtn {
        position: absolute;
        right: 25px;
        bottom: 23px;
        .icon-refresh {
            margin-right: 5px; } } }
.recordingBtns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    gap : 1rem;
    img {
        margin-right: 0.5rem; }
    .activeShow {
        display: none; } }

.recordingListWrap {
    background: #FFFFFF;
    box-shadow: 4px 15px 20px #E8EFFC;
    padding: 20px;
    margin-bottom: 20px;

    .listHead {
        background: linear-gradient(90.03deg, #707E9B -17.19%, #495878 111.86%);
        border-radius: 2px;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        div {
            @include font(14px, 19px, #fff); } }
    .listFooter {
        display: flex;
        justify-content: flex-end;
        margin-top: 23px; }
    .recordList {
        .recordItem {
            display: flex;
            align-items: center;
            height: 45px;
            border-bottom: 1px solid #EBF0FC;
            @include font(12px, 16px, #515266);
            padding: 0 14px;
            .audioFile {
                display: flex;
                align-items: center;
                width: 21%;
                svg {
                    margin-right: 11px;
                    &.icon-play {
                        width: 10px;
                        height: 12px;
                        path {
                            stroke: #78CF4D; } }
                    &.icon-pause {
                        width: 18px;
                        height: 18px;
                        path {
                            stroke: $basePink; } } } }

            .audioTime {
                width: 10%; }
            .fileStatus {
                width: 10%; }
            .audioName {
                width: 30%; }
            .audioDelete {
                display: flex;
                justify-content: flex-end;
                flex: 1; } } } }
audio {
  filter: sepia(20%) saturate(70%) grayscale(1) contrast(99%) invert(12%);
  width: 210px;
  height: 25px; }
