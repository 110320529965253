.transcriber-container {
    background: $white;
    .transcriber-header {
        justify-content: space-between;
        align-items: center;
        height: 89px;
        margin: 0 3%; }
    .trancriber-detail {
        background: $black;
        height: 82px;
        justify-content: space-between;
        padding: 0 3%;
        align-items: center;
        .transcriber-info {
            justify-content: space-between;
            padding: 21px 0;
            li {
                line-height: 21px;
                float: left;
                margin-right: 30px;
                div {
                    color: $white; }
                label {
                    color: $link; } } }
        .guideline {
            border: 1px solid $link;
            background: none;
            color: $white;
            text-transform: capitalize;
            font-weight: normal;
            margin-right: 10px; }
        .shortcut {
            background: $link;
            color: $white;
            text-transform: capitalize;
            font-weight: normal; } }
    .transcriber-waves {
        .transWaveWrap {
            background: #CECECE; }
        .transAudioWrap {
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto; }
        .audioWrap {
            background: #fff;
            padding: 5px 0; } } }
.segment-container {
    margin: 0 3%;
    &.segmentcontainer-mar-top {
        .projectTableWrap {
            .segment-data-wrap {
                height: calc(100vh - 273px);
                overflow: hidden;
                overflow-y: auto !important;
                margin: 0; } } }
    .segment-header {
        background: #222756;
        padding: 0 0 0 20px;
        border-radius: 3px;
        height: 50px;
        justify-content: space-between;
        align-items: center;
        color: $white;
        font-weight: bold;
        font-size: 16px;
        .action-btn-group {
            height: 100%;
            button {
                background: none;
                outline: 0 none;
                border: 0 none;
                cursor: pointer;
                color: $white;
                padding: 0 20px;
                font-size: 13px;
                &:hover {
                    background: #0E1238; } } } }
    .seg-info {
        background: #E9EBFD;
        height: 50px;
        &.errorWrap {
            background: #F8EBEB; }
        .speaker-selection {
            background: $link;
            position: relative;
            border-right: 0 none;
            height: 100%;
            border-radius: 0;
            color: #fff;
            &::after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-right: 5px solid #E9EBFD;
                right: 0;
                top: 42%; }
            .selected-speaker {
                text-align: center;
                font-weight: bold;
                font-size: 16px;
                color: $white; }
            .speaker-options {
                background: $white;
                position: absolute;
                top: 50px;
                left: 0;
                width: 100%;
                display: none; }
            .open {
                display: block;
                z-index: 3;
                div {
                    height: 40px;
                    display: flex;
                    align-items: center;
                    padding: 0 10px;
                    color: #5F627A;
                    font-size: 13px;
                    border: 1px solid #DEE0F3;
                    border-top: 0 none; }
                .select-speaker {
                    background: #222756;
                    color: $white;
                    font-weight: 600; }
                .active {
                    background: $link;
                    color: $white; } } }
        .sentiment-section {
            padding: 12px 15px 10px 10px;
            align-items: center;
            justify-content: space-between;
            select {
                width: 146px;
                height: 34px; }
            .status-container {
                position: relative;
                .error-popup {
                    width: 272px;
                    background: #EDC2C2;
                    border: 1px solid #D90000;
                    box-sizing: border-box;
                    border-radius: 3px;
                    right: 30px;
                    position: absolute;
                    top: 0px;
                    padding: 15px;
                    color: #532F2F;
                    font-weight: normal;
                    z-index: 5;
                    display: none;
                    transform: translateY(-42%);
                    &::before {
                        content: '';
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-top: 5px solid transparent;
                        border-bottom: 5px solid transparent;
                        border-left: 5px solid #D90000;
                        right: -5px;
                        top: 50%;
                        transform: translateY(-5px); }
                    &::after {
                        content: '';
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-top: 5px solid transparent;
                        border-bottom: 5px solid transparent;
                        border-left: 5px solid #EDC2C2;
                        right: -4px;
                        top: 50%;
                        transform: translateY(-5px); }
                    .error-item {
                        margin-bottom: 12px;
                        &:last-child {
                            margin-bottom: 0; } }
                    strong {
                        color: #D90000;
                        margin-bottom: 5px;
                        display: block; } }
                &:hover {
                    .error-popup {
                        display: block; } } } } }
    .seg-summary {
        height: 50px;
        align-items: center;
        padding: 0 15px;
        color: #5F627A; }
    .seg-info {
        > div {
            flex: 1;
            padding: 17px 20px 0 20px;
            border-right: 1px solid #CDD1F0;
            font-weight: 600; } }
    .segmentTextarea {
        width: 100%;
        height: 100%;
        border: none;
        overflow: auto;
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        resize: none; }
    .segmentTextareaRtl {
        direction: rtl; }
    .rta {
        width: 100%;
        position: relative; } }
.sticky {
    position: fixed;
    top: 0;
    z-index: 99999;
    width: 100%; }
.segmentcontainer-mar-top {
    margin-top: 230px; }

