.userSection {
  background: $white;
  height: 80px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  margin-bottom: 20px;
  .welcomeUser {
    @include font(18px, 21px, #717394, roboto);
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    padding-right: 70px; }
  .userInfoWrap {
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 5px;
    .userAvatar {
      width: 41px;
      height: 41px;
      background: #ccc;
      border-radius: 50%;
      margin-right: 10px; }
    .userName {
      @include font(13px, 15px, #717394, roboto);
      display: flex;
      align-items: center;
      text-transform: capitalize;
      cursor: pointer;
      height: 41px;
      .twoWayArrow {
        border-color: $basePink;
        margin-left: 20px;
        transform: rotate(45deg);
        margin-top: 0; } }
    .profileOptions {
      position: absolute;
      background: $link;
      box-shadow: 10px 10px 30px rgba(43, 68, 120, 0.2);
      border-radius: 6px;
      width: 157px;
      top: 45px;
      z-index: 99;
      display: none;
      li {
        padding: 16px 15px;
        @include font(12px, 14px, $white, roboto);
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          text-transform: capitalize; }
        &.profileItem,
        &.changePwdItem,
        &.signOutItem {
          img, svg {
            width: 20px;
            height: 20px;
            margin-right: 9px; } }
        &.signOutItem svg {
          fill: $white; }
        &:first-child {
          border-radius: 6px 6px 0 0; }
        &:last-child {
          border-radius: 0 0 6px 6px; }
        &:hover {
          background: #d24236; } } }
    &:hover {
      .profileOptions {
        display: block; } } } }

.projectListWrap {
  .projectList {
    justify-content: space-between;
    .projectBlock {
      width: 220px;
      height: 75px;
      padding: 12px 0 17px 20px;
      color: $white;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
      .numbers {
        @include font(40px, 53px, $white);
        font-weight: bold; }
      .projectStats {
        @include font(14px, 18px);
        text-transform: uppercase;
        border-left: 1px solid rgba(255,255,255, .30);
        padding-left: 15px;
        margin-left: 13px;
        div:first-child {
          font-weight: 600; } }

      &.readyToDeliver {
        background: linear-gradient(90deg, #EE6054 -2.44%, #FFBAB5 103.25%),linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));
        box-shadow: 2px 15px 50px 0px rgba(238, 96, 84, 0.25); }

      &.onGoingProj {
        background: linear-gradient(90deg, #34C6C3 -2.44%, #72FCF9 103.25%),linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));
        box-shadow: 2px 15px 50px 0px rgba(52, 198, 195, 0.25); }
      &.pendingProj {
        background: linear-gradient(90deg, #22A4EE -2.35%, #7ED0FF 99.59%), rgba(255, 255, 255, 0.9);
        box-shadow: 2px 15px 50px rgba(34, 164, 238, 0.25); }
      &.customersOnBoard {
        background: linear-gradient(90deg, #8CD06B -2.35%, #B0F68D 99.59%), rgba(255, 255, 255, 0.9);
        box-shadow: 2px 15px 50px rgba(120, 207, 77, 0.25); } } } }
.dashboardContent {
  margin-top: 50px;
  .block {
    flex: 1;
    .accordion {
      .accordionItem {
        margin-bottom: 2px;
        header {
          .customerName {
            @include font(14px, 18px, #515266); }
          .arrowWrap {
            align-items: center; }
          .deliverDate {
            @include font(12px, 16px, #717394);
            display: flex;
            align-items: center;
            .priorityStat {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              cursor: pointer;
              &.red {
                background: #FF2D2D; }
              &.orange {
                background: #FFAB2D; }
              &.green {
                background: #39E373; } }
            .dateDeliver {
              padding: 0 19px 0 10px; } }
          .twoWayArrow {
            border-color: $link; } }
        section {
          ul li {
            @include font(13px, 17px, #717394);
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 52px;
            padding: 0 10px;
            &:not(:last-child) {
              border-bottom: 1px solid #F0F3F9; }
            .productTitle {
              display: flex;
              align-items: center;
              flex-basis: 15%;
              svg {
                fill: #515266; }
              span {
                padding-left: 12px;
                cursor: pointer;
                text-decoration: underline;
                color: $link;
                font-weight: 600; } } } } } } }

  .criticalAlert {
    width: 270px;
    margin-left: 30px;
    @include font(13px, 18px, #717394);
    svg {
      fill: #717394; }
    h2 {
      @include font(16px, 21px, #3E3E4F);
      font-weight: 600;
      margin-bottom: 22px; }
    .alertList {
      .alertItem {
        &:not(:last-child) {
          padding-bottom: 20px;
          border-bottom: 1px solid #DFE5F2; }
        &:not(:first-child) {
          padding-top: 25px; }
        .alertAvtar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #DBE3F1;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center; }
        .alertContent {
          flex: 1;
          .alertFooter {
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
            .alertDate {
              color: #BABBCA; }
            .viewMore {
              color: $link;
              text-decoration: underline;
              text-transform: capitalize; } } } } } } }
.projectCoordinatorContent {
  .search {
    input {
      background: #F1F5FD;
      border: 1px solid #D0D5DF;
      border-radius: 8px; }
    .searchBtn {
      svg {
        fill: $basePink; } } } }
.regionSelectWrap {
  border-bottom: 1px solid #D9E0EF;
  padding-bottom: 20px;
  margin-bottom: 20px; }
.regionselect {
  select {
    background: #E6ECF9;
    border: 1px solid #22A4EE;
    border-radius: 6px;
    @include font(14px, 19px, #000);
    font-weight: 700;
    text-transform: uppercase;
    color: #000;
    appearance: auto;
    margin-right: 14px; }
  .addRate {
    background: #D6F4F3;
    border: 1px solid #34C6C3; } }
.adminDetails {
  > div {
    margin-bottom: 7px;
    margin-left: 8px; }
  &:first-child div {
    margin-right: 76px; }
  .firstDiv {
    margin-right: 0px !important;
    border-right: 1px solid #D9D9D9;
    div {
      margin-bottom: 7px; } }

  label {
    @include font(14px, 19px, #666);
    margin-right: 5px; }
  .value {
    color: #000;
    font-weight: 700; } }

.userListHeader {
  background: #F1F5FD;
  display: flex;
  justify-content: space-evenly;
  padding: 1rem 0rem;
  > div {
    display: flex;
    align-items: center;
    width: 25%;
    padding-left: 12px; }
  img {
    margin-right: 0.5rem; } }
