.RegisterWrap {
  background: #E2E4F8;
  .registerLHS {
    background: url(../images/login-LHS-bg.png) no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .logo {
      width: 254px;
      align-self: center;
      display: flex; }
    .loginFooter {
      @include font(11px, 15px, #333);
      text-align: center;
      a {
        color: #333; } } }
  .registerRHS {
    width: calc(100% - 450px);
    display: flex;
    justify-content: center;
    background: #F5F5F5; } }

.voiceVerifyAudio {
  background-color: white;
  box-shadow: aqua;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 10px; }

.voiceVerifyAudio {
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      border: none;
      background: none; } } }

.timestamp {
  #stopwatch {
    background-color: black;
    color: white;
    padding: 8px; } }
.registrationForm {
  width: 510px;
  &.rgsWrapper {
    width: 100%; }
  .welcometxt {
    @include font(18px, 23.94px, #41425E);
    margin-bottom: 43px;
    span {
      font-weight: 700; } }
  .registerFormWrap {
    .firstLastField {
      justify-content: space-between;
      .inputWrap {
        flex-basis: 48%; } }

    .inputWrap.error {
      select {
        border: 1px solid #EE6054;
        background: #fff3f5; }
      .css-2b097c-container {
        input {
          border: 0 !important;
          background: none !important; } } }
    .css-2b097c-container {
      .css-yk16xz-control,
      .css-1pahdxg-control {
        background: #fff;
        border: 1px solid #ddd;
        height: 49px;
        width: 100%;
        padding: 0 0 0 24px;
        &::placeholder {
          @include font(14px, null, #333); }
        &.error {
          input::placeholder {
            color: #E04F5F; } } }

      .css-1wa3eu0-placeholder {
        @include font(14px, null, #333); }

      .css-1okebmr-indicatorSeparator {
        display: none; }
      .css-b8ldur-Input {
        input {
          height: auto; } } }

    .inputWrap {
      margin-bottom: 20px;
      &.gender {
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 100px;
        height: 49px; }
      input {
        background: #fff;
        border: 1px solid #ddd;
        height: 49px;
        width: 100%;
        padding: 0 0 0 24px;
        &::placeholder {
          @include font(14px, null, #333); }
        &.error {
          input::placeholder {
            color: #E04F5F; } } }
      select {
        appearance: auto;
        padding: 0 24px;
        border-radius: 100px;
        height: 47px;
        margin: 0;
        width: 100%;
        border-radius: 0px;
        background: #fff;
        color: #3e3e4f; }
      .inputErrorMsg {
        margin-left: 25px; }
      &.mobileField {
        position: relative;
        select {
          border-radius: 100px 0 0 100px;
          padding: 0 0 0 20px;
          border: 0;
          border-right: 1px solid #ddd;
          top: 0;
          bottom: 0;
          margin: 1px 0 0 0;
          height: 47px;
          width: 83px; }
        input {
          padding-left: 100px !important; } } }
    .formFooter {
      text-align: center;
      button {
        @include font(16px, 19px);
        height: 49px;
        text-transform: uppercase;
        width: 100%;
        border: 0;
        cursor: pointer;
        &.rgsBtn {
          width: 48%;
          margin-top: 59px; }
        &.roundedBtn-Blue {
          background: $link;
          color: #fff;
          font-family: roboto;
          font-weight: 700; }
        &.roundedBtn-White {
          background: #fff;
          border: 1px solid $link;
          color: $link;
          font-family: $baseFont; } }
      .orTxt {
        @include font(12px, 16px, #000, $baseFont);
        font-weight: 600;
        margin: 18px 0 21px;
        text-align: center; }

      .loginLink {
        text-align: center;
        margin-top: 20px;
        @include font(13px, 17.29px, $link);
        a {
          color: $link;
          text-decoration: underline;
          font-weight: 600; } } } } }

.passwordField {
  position: relative;
  .passwordEye {
    position: absolute;
    top: 15px;
    right: 22px;
    cursor: pointer;
    svg {
      width: 18px;
      height: 18px; }
    &.active {
      svg > path {
        fill: $link; } } } }
.loginTitle {
  @include font(22px, 29px, #41425E);
  strong {
    font-weight: bold; } }
.loginForm {

  .orDivider {
    @include font(14px, 19px, #bbb);
    padding: 0 13px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    &::before,
    &::after {
      content: '';
      position: absolute;
      background: #eee;
      height: 1px;
      width: 45.2%; }
    &::after {
      right: 0; }
    &::before {
      left: 0; } }
  .remember-forgotPass {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0 38px;
    .rememberMeWrap {
      display: flex;
      align-items: center;
      cursor: pointer;
      input {
        width: 15px;
        height: 15px;
        border: 1px solid #ccc;
        margin-right: 9px;
        border-radius: 2px; }
      label {
        @include font(12px, 16px, #999);
        font-weight: 600;
        margin-top: -1px; } }
    .ForgotCode {
      @include font(13px, 17px, $link);
      text-decoration: underline;
      cursor: pointer; }
    .IsmanagerAccess {
      @include font(13px, 17px); } } }

.partTimeWrap {
  padding: 30px 0;
  border-top: 1px solid #B9BCCF;
  display: flex;
  align-items: center;
  color: #41425E;
  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    background: #E7E9FB;
    border: 1px solid #A1A5C1;
    border-radius: 4px; }
  label {
    @include font(12px, 16px);
    margin: 0 13px 0 7px;
    border-right: 1px solid #A1A5C1;
    padding-right: 10px; }
  .hrsInput {
    width: 65px;
    height: 27px;
    background: #FFFFFF;
    border: 1px solid #22A4EE;
    border-radius: 4px;
    padding: 0 8px;
    margin-right: 9px; }
  .noBorder {
    border: 0; } }
