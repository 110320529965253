body {
  @include font(14px, 16px, #000, $baseFont);
  height: 100%; }
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none; }

div[id=root], html {
  height: 100%; }

a {
  cursor: pointer;
  text-decoration: none;
  color: $link;
  &:hover,
  &:focus, {
    text-decoration: none;
    color: $link;
    outline: none; } }

button {
  border: none;
  background: none; }
button[disabled],
html input[disabled] {
  cursor: default;
  color: #CC9999; }
li {
  list-style-type: none; }
.clearfix::after {
  content: "";
  clear: both;
  display: table; }

.form-control {
  height: 40px;
  @include font(16px, 18px, #aaa);
  border: 1px solid #eee;
  box-shadow: none; }

select::-ms-expand {
  display: none; }

.font-semibold {
  font-weight: 600; }

.h-100vh {
  height: 100vh; }

.font-large {
  font-size: 1rem; }
.flex-column {
  flex-direction: column; }

.gap-2 {
  gap: 0.5rem; }

.flex-wrap {
  flex-wrap: wrap; }
.gap-4 {
  gap: 1rem; }

.mb-2 {
  margin-bottom: 0.75rem; }
.mt-2 {
  margin-top: 0.75rem; }

.cursorPointer {
  cursor: pointer; }
.hide {
  display: none !important; }
.show {
  display: block; }

.btn-disabled {
  opacity: 0.5;
  cursor: default !important; }
.relative {
  position: relative; }
.mb-2 {
  margin-bottom: 0.5rem; }
.mb-4 {
  margin-bottom: 1rem; }
.d-flex {
  display: flex; }
.flex-wrap {
  flex-wrap: wrap; }
.align-center {
  align-items: center; }
.align-start {
  align-items: start; }
.align-baseline {
  align-items: baseline; }
.justify-between {
  justify-content: space-between; }
.justify-center {
  justify-content: center !important; }
.justify-end {
  justify-content: flex-end; }
.center {
  text-align: center; }
.container {
  width: 100%; }
.oneThirdContainer {
  width: 30%; }


.twoWayArrow {
  width: 8px;
  height: 8px;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  transform: rotate(45deg); }

// SEARCH
.search {
  position: relative;
  input {
    width: 335px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #D0D5DF;
    background: #F1F5FD;
    border-radius: 6px;
    padding: 0 14px 0 17px;
    font-size: 13px;
    color: #515266;
    &::placeholder {
      @include font(13px, 17px, #BABBCA); }
    &:focus {
      border: 1px solid $basePink !important;
      color: $basePink; } }
  .searchBtn {
    background: none;
    border: 0;
    position: absolute;
    height: 40px;
    width: 40px;
    right: 0;
    border-radius: 0 6px 6px 0;
    cursor: pointer;
    svg {
      fill: $basePink;
      width: 16px;
      height: 16px; } } }

// TABLE
.tableWrap {
  background: #FFFFFF;
  box-shadow: 4px 15px 20px #E8EFFC;
  border-radius: 6px;
  padding: 20px;
  margin-top: 24px;
  header {
    align-items: baseline;
    justify-content: space-between; } }

.block {
  background: $white;
  box-shadow: 4px 15px 20px #E8EFFC;
  border-radius: 6px;
  padding: 24px 20px;
  h2 {
    @include font(16px, 21px, #3E3E4F);
    text-transform: capitalize;
    margin-bottom: 25px; } }

// BUTTON
.btn {
  border-radius: 3px;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  text-align: center;
  padding: 0 13px;
  font-size: 14px;
  font-weight: bold;
  border: 0;
  cursor: pointer;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover, &:focus {
    outline: 0; } }
.btnSmall {
  border-radius: 3px;
  height: 24px;
  text-transform: capitalize;
  padding: 0 5px;
  font-size: 10px;
  font-weight: normal;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  @include font(12px, 24px, null, roboto);
  &:hover, &:focus {
    outline: 0; } }

.btnExcelGreen {
  background: #fff;
  color: #185C37;
  border: 1px solid #185C37;
  box-shadow: 1px 3px 11px rgba(24, 92, 55, 0.46);
  &:hover {
    color:#185C37 {}
    background: #69e1a02e; }
  img {
    margin-right: 5px; } }

.btnGreen-small {
  background: #fff;
  color: #78CF4D;
  &:hover {
    background: #78cf4d26;
    border: 1px solid #78CF4D;
    color: #78CF4D; }
  &:active {
    background: #78CF4D;
    color: #fff; } }
.btnRed-small {
  background: #fff;
  color: $basePink;
  &:hover {
    background: #ee605426;
    border: 1px solid $basePink;
    color: $basePink; }
  &:active {
    background: $basePink;
    color: #fff; } }
.btnBlue-small {
  background: rgba(34, 164, 238, 0.1);
  border: 1px solid #22A4EE;
  color: #22A4EE; }
.skyBtn-border {
  background: $white;
  border: 1px solid #34C6C3;
  color: #34C6C3; }

.adminUserBtn {
  background:#22A4EE {}
  color:#fff {}
  &:hover {
    background :#0085D0 {} } }

.skyBtn-fill {
  background: #34C6C3;
  color: $white; }

.orangeBtn {
  background: $white;
  box-shadow: 4px 10px 15px rgba(238, 96, 84, 0.2);
  color: #ee6054;
  border: 1px solid #ee6054;
  &:hover {
    background: #ed6154;
    color: $white; }
  &:active {
    background: #DE4438;
    color: $white; } }


.borderBtn {
  border: 1px solid $link;
  color: $link;
  background: $white; }

.blueBtn-fill {
  height: 40px;
  background: #fff;
  box-shadow: 4px 10px 15px rgba(34, 164, 238, 0.2);
  border-radius: 6px;
  color: #22A4EE;
  &:hover {
    background: #22A4EE;
    color: $white; }
  &:active {
    background: #0085D0; } }

.pinkBtn {
  border: 1px solid #C643D8;
  box-shadow: 4px 10px 15px rgba(238, 96, 84, 0.2);
  border-radius: 6px;
  color: #C643D8;
  background: $white;
  &:hover {
    background: #C643D8;
    color: $white;
    svg {
      fill: $white; } } }
.greyBtn {
  background: #FAFCFF;
  border: 1px solid #DCE0EA;
  color: #7C7F85;
  &:hover {
    background: #7c7f8526;
    color: #7C7F85; }
  &:active {
    background: #7C7F85;
    color: #FAFCFF; } }
.darkGreenBtn {
  background: $white;
  color: #34C6C3;
  border: 1px solid #34C6C3;
  &:hover {
    color: $white;
    background: #34C6C3; }
  &:active {
    background:#2a8f8c {} } }
.blackBtn {
  background: #000000;
  opacity: 0.8;
  border-radius: 3px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include font(12px, 16px, #fff, $baseFont);
  padding: 0 13px;
  cursor: pointer; }

// INPUT
.inputBlock {
  display: flex;
  flex-direction: column;
  .label, label {
    margin-bottom: 9px;
    color: #717394;
    text-transform: capitalize;
    .requiredField {
      color: #FF0000; } } }

.inputWrap {
  position: relative;
  input,
  textarea {
    background: #FAFCFF;
    border: 1px solid #DCE0EA;
    border-radius: 4px;
    height: 40px;
    @include font(13px, 17px, #3e3e4f);
    width: 100%;
    padding: 0 10px;
    &:focus, &.updated {
      background: #FFFFFF;
      border: 2px solid $link;
      color: $link;
      font-weight: 600;
      &::placeholder {
        color: $link; } }
    &::placeholder {
      color: #CBD4E1; } }
  &.error {
    input,
    textarea {
      background: #FFF3F5 !important;
      border: 1px solid #E04F5F !important;
      color: #E04F5F; } }
  .inputbadge {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 10px;
    svg {
      fill: $link; } }
  textarea {
    resize: none;
    width: 100%;
    height: 80px;
    padding: 11px 10px; } }

.inputErrorMsg {
  @include font(11px, 15px, #E04F5F);
  text-transform: capitalize; }

.selectWrap {
  position: relative;
  .twoWayArrow {
    display: inline-block;
    border-color: $link;
    position: absolute;
    top: 13px;
    right: 15px; }
  select {
    width: 100%; } }

select {
  border: 1px solid #DCE0EA;
  box-sizing: border-box;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #FAFCFF;
  cursor: pointer;
  width: 190px;
  height: 40px;
  padding: 0 30px 0 10px;
  color: #B8BECC;
  font-weight: 400;
  &:focus {
    border-color: $link;
    color: $link;
    font-weight: 600;
    option {
      color: $black; } } }

.label {
  @include font(13px, 17px, #717394);
  margin-right: 11px;
  text-transform: capitalize;
  .requiredField {
    color: #FF0000; } }

// MOBILE FIELD
.mobileField {
  position: relative;
  select {
    position: absolute;
    height: 38px;
    width: 83px;
    border-radius: 4px 0 0 4px;
    padding: 0 0 0 12px;
    border: 0;
    left: 1px;
    top: 1px;
    border-right: 1px solid #ddd;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../images/selectArrow.png) no-repeat 88% center;
    cursor: pointer; }
  input {
    padding-left: 93px !important; } }

.contentLeft {
  flex: 1; }

.contentRight {
  width: 270px;
  margin-left: 30px; }

.blockFooter {
  display: flex;
  align-items: center;
  justify-content: center; }

.pageRight {
  flex: 1;
  padding: 30px;
  background: #F1F5FD;
  margin-left: 300px;
  min-height: 100vh; }

// full Page Loader

.fullPageLoader {
  .overlay {
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .loaderInner {
    background: $white;
    border-radius: 4px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px; } }

// Accordion
.accordion {
  .accordionItem {
    header {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      background: #F1F5FD; } } }

.breadcrumWrap {
  justify-content: space-between;
  align-items: flex-end;
  .search {
    margin-right: 21px; } }
.breadcrumb {
  @include font(13px, 15px, #717394, roboto);
  display: flex;
  align-items: center;
  li {
    text-transform: capitalize;
    &:not(:last-child) {
      background: url(../images/ArrowRight.svg) no-repeat right 0 center;
      padding-right: 25px;
      margin-right: 10px; } } }
.greenBtn {
  padding: 0 17px;
  border: 1px solid #78cf4d;
  background: #fff;
  color: #78cf4d;
  &:hover {
    background: #78cf4d;
    color: #fff; } }

.uploadBtn {
  min-width: 91px;
  margin-right: 20px; }

.tabsWrap {
  border-bottom: 1px solid #E2E8F6;
  display: flex;
  margin-bottom: 20px;
  .tabItem {
    @include font(16px, 19px, #717394, roboto);
    padding-bottom: 10px;
    position: relative;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 40px; }
    &.active,
    &:hover {
      color: #EE6054;
      font-weight: 700;
      &::after {
        content: '';
        position: absolute;
        bottom: -1.5px;
        width: 100%;
        height: 3px;
        background: #EE6054;
        left: 0; } } } }
.Tabs-filled {
  .tabsFilledItem {
    background: $white;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 176px;
    cursor: pointer;
    margin-bottom: 2px;
    &:not(:last-child) {
      margin-right: 3px; }
    @include font(14px, 16px, #22A4EE, roboto);
    &:first-child {
      border-radius: 6px 0 0 0; }
    &:last-child {
      border-radius: 0 6px 0 0; }
    &.active,
    &:hover {
      background: #22A4EE;
      color: $white; } } }
.Tabs-filledContent {
  .tableWrap {
    margin: 0; } }
.ratesContent,
.prioritiesContent,
.deadlinesContent {
  .breadcrumWrap {
    align-items: center; } }
.createNewPriBtn {
  margin-bottom: 20px; }
.justify-end {
  justify-content: flex-end; }
.domainTableList,
.prioritiesTableList,
.EntitiUserTable,
.EntitiCustomerTable,
.EntitiVendorTable {
  header {
    display: none; }
  table {
    margin: 0; } }
.uploadFileWrap {
  width: 100%;
  height: 110px;
  position: relative;
  border: 1px solid #DCE0EA;
  border-radius: 3px;
  background: #FAFCFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  .fileTxt {
    @include font(22px, 29px, #ADBCD3); }
  input {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    opacity: 0;
    cursor: pointer; } }
.mar-right-20 {
  margin-right: 20px; }
.btnGroup {
  display: flex;
  justify-content: flex-end;
  .btn:first-child {
    margin-right: 15px; } }
.pageTitle {
  @include font(16px, 21px, #3e3e4f);
  font-weight: 600;
  margin-bottom: 25px; }
.uploadFileList {
  width: 100%;
  padding: 8px 15px;
  @include font(12px, 19px, #515266);
  display: none;
  li {
    list-style-type: auto;
    list-style-position: inside; } }
option:disabled {
  background: #EBEBE4; }
.rta__autocomplete {
  background: #FFFFFF;
  box-shadow: 0px 4px 40px rgba(40, 43, 61, 0.1);
  width: 248px;
  height: 240px;
  position: absolute;
  overflow: hidden;
  overflow-y: auto;
  z-index: 99999;
  .rta__item {
    height: 29px;
    margin: 6px 0;
    padding: 5px 10px;
    color: #5F627A;
    &:hover {
      background: #22A4EE;
      color: #fff; } } }
.rta__entity--selected {
  color: #fff;
  text-decoration: none;
  background: #0366d6; }
.slide-pane {
  background: #E9EBFD;
  border-radius: 15px 0 0 0;
  .slide-pane__header,
  .slide-pane__content {
    background: #E9EBFD; }
  .slide-pane__header {
    height: 90px;
    border-radius: 15px 0 0 0;
    .slide-pane__title {
      @include font(26px, 30px, #3E3E4F, roboto);
      font-weight: bold; } }
  .slide-pane__content {
    padding: 0;
    .errortitle {
      background: #222756;
      padding: 15px 32px;
      @include font(16px, 19px, #fff, roboto);
      font-weight: 700;
      display: flex;
      align-items: center;
      svg {
        margin-right: 8px; } }
    .errorItem {
      @include font(13px, 17px, #D90000);
      font-weight: 600;
      padding: 15px 5px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #C5C8E9;
      margin: 0 20px;
      .twoWayArrow {
        margin: 0 10px 0 0;
        border-color: #D90000; } } } }
.slide-pane__overlay {
  z-index: 99999; }
.LogoWrap {
  width: 190px;
  height: 160px;
  margin: 0 auto;
  background: #E2E4F8;
  padding: 0 13px 14px;
  border-radius: 0 0 20px 20px;
  display: flex;
  align-items: flex-end;
  .logo {
    width: 163.29px;
    height: 50px; } }
.rejectFormWrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
  label {
    @include font(14px, 19px, #222756);
    font-weight: 600;
    margin-bottom: 13px; }
  textarea {
    background: #F8F8FF;
    border: 1px solid #A1A5C1;
    border-radius: 10px;
    width: 100%;
    height: 200px; } }
.popupFooter {
  justify-content: flex-end; }
.spelling-error {
  background-color: #ffd54f; }
/*#zoomview-container
  .konvajs-content
    width: 100% !important
    canvas
      width: 100% !important*/
.imgwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    text-decoration: none;
    position: relative;
    pointer-events: auto;
    cursor: default;
    color: #515266;
    font-family: "segoe", Arial, sans-serif;
    font-size: 12px;
    font-weight: normal;
    span {
      position: absolute;
      display: none;
      transform: scale(0);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -160%) scale(1.5);
      overflow: hidden;
      img {
        width: 200px;
        height: auto;
        pointer-events: auto; } }
    &:hover {
      span {
        display: block;
        transform: translate(-30%, -160%) scale(1.5);
        transition: transform 0.3s ease-in-out; } } } }
